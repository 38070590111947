.main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.about-me {
    margin-bottom: 24px;
    border-bottom: 2px #D1920D solid;
}

p {
    font-size: 19px;
}

.skills {
    margin-top: 5rem;
    text-align: center;
    border-bottom: 2px #D1920D solid;
    margin-bottom: 24px;
}


.cards {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
}

.content {
    padding-left: 8px;
}

.skills-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}