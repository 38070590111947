.section-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.heading-name {
    font-size: 42px;
}

.name {
    color: #D1920D;
    border-bottom: 1px solid #D1920D;
}

.type {
    font-size: 24px;
    margin-bottom: 48px;
    border-bottom: 2px #D1920D solid;
}

.home-about-me {
    font-size: 20px;
}

.about-link-p {
    font-size: 18px;
}
.projects-link-p {
    font-size: 18px;
}

.about-link {
    color: #D1920D;  
}

.about-link:hover {
    color: #D1920D;
    transform: translateY(-3px);
}

.contact-section {
    margin-top: 32px;
}

.contact {
    border-bottom: 2px #D1920D solid;
}

.footer-link {
    padding-right: 16px;
    padding-left: 24px;
}

.real-link {
    color: #fff;
}

.real-link:hover {
    color: #fff;
}

.contact-links {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

@media screen and (max-width: 462px) {
    .heading-name {
        display: flex;
        flex-direction: column;
        font-size: 32px;
    }

    .name {
        border-bottom: none;
    }
}

@media screen and (max-width: 350px) {
    .real-link {
        display: none;
    }

    .contact-links {
        flex-direction: row;
        margin-bottom: 12px;
    }

}