* {
    padding: 0;
    margin: 0;
    color: #fff;
    font-family: 'Roboto', sans-serif;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

#root {
    background-color: #131313;
    min-width: 100vw;
    width: 100%;
    min-height: 100vh;
    height: 100%;
}

.particles {
    position: fixed !important;
    width: 100vw;
    height: 100vh;
    z-index: 0 !important;
}

.main {
    height: 100%;
    z-index: 0 !important;
}

.logo {
    max-width: 175px;
    max-height: 175px;
}

.navLinks {
    transition: all 243ms ease;
}

.navLinks:hover {
    color: #fff;
    transform: translateY(-3px);
    border-bottom: 3px #D1920D solid;
}

.navbar-brand {
    flex: 1;
}